import * as React from 'react';

export function NavBar({ children }: { children?: React.ReactNode }) {
    return (
      <div
        style={{ width: '100%', height: 42, backgroundColor: 'black' }}
        className="px1 flex justify-between items-center"
      >
        {children}
      </div>
    );
  }