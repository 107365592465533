export default {
  firebaseConfig: {
    apiKey: 'AIzaSyCTbAcZjAYyZ6DmTgyiW1WspYoTKZD4-Y4',
    authDomain: 'covid-date.firebaseapp.com',
    databaseURL: 'https://covid-date.firebaseio.com',
    projectId: 'covid-date',
    storageBucket: 'covid-date.appspot.com',
    messagingSenderId: '131064188005',
    appId: '1:131064188005:web:68316b85ea21962832ca8c',
    measurementId:
      process.env.NODE_ENV === 'development' ? "DEV" : 'G-YFXEZQQMTC',
  },
  date: {
    defaultDurationMinutes: 15,
    maxBlurDurationMinutes: 3,
  },
  tokBoxEmbedID:
    process.env.NODE_ENV === 'development'
      ? 'f2234aa3-5871-49d0-b36f-f34d38320bbe'
      : '2ec242b0-24e7-43d7-acd9-b5df46b23dea',
};
