import * as React from 'react';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import Countdown from 'react-countdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Helmet from 'react-helmet';

import { Card } from 'baseui/card';
import { Button } from 'baseui/button';
import * as t from 'baseui/typography';
import { ArrowLeft, ChevronRight, ChevronLeft } from 'baseui/icon';

import svgThankyou from 'src/illustrations/thank-you.svg';
import config from 'src/config/settings';
import { QUESTIONS } from 'src/config/questions';
import { calculatePercentBlur } from 'src/lib/utils';
import { db } from 'src/lib/firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { NavBar } from 'src/components/nav';
import love from 'src/illustrations/1.svg';

export enum DateState {
  OPEN = 'open',
  EXPIRED = 'expired',
}

export type Date = {
  questionNumber: number;
  name: string;
  createdAt: any;
  state: DateState;
  blurOn: boolean;
  expires: string;
  seenQuestions: number[];
};

export function DateInstructions({ onComplete }: { onComplete: () => void }) {
  return (
    <div>
      <Card>
        <div className="center">
          {
            <div>
              <h2 style={{ fontFamily: 'Libre Baskerville' }}>
                Hi There! Are you ready?
              </h2>
              <img src={love} width={300} />
              <Card title={<span style={{ color: 'gray' }}>How it works</span>}>
                <div className="h5">
                  Click "Join Video Date" to enter the date room. A video will
                  appear with questions from the New York Times article{' '}
                  <a
                    style={{ textDecoration: 'underline' }}
                    href="https://www.nytimes.com/2015/01/11/style/36-questions-that-lead-to-love.html"
                    target="_blank"
                  >
                    36 Questions That Lead To Love
                  </a>{' '}
                  to encourage some talking points. The date will expire after{' '}
                  <b>{config.date.defaultDurationMinutes} minutes</b>
                </div>
              </Card>

              <div className="mt1">
                <Button
                  onClick={onComplete}
                  shape="pill"
                  $style={{ width: '100%' }}
                >
                  Join Video Date
                </Button>
              </div>
            </div>
          }
        </div>
      </Card>
    </div>
  );
}

export function DateRoom({ match }) {
  const [percentBlur, setPercentBlur] = React.useState(1);
  const blurPx = 25 * (percentBlur / 100) * 100;

  const defaultDateState = {
    questionNumber: 0,
    active: false,
  };

  const dateID = match.params.id;
  const dateDoc = db.doc(`dates/${dateID}`);
  const [date, loading] = useDocumentData<Date>(dateDoc);
  const value = date;
  const dateState = _.defaults({}, value, defaultDateState);
  const questionNumber = dateState.questionNumber || 0;
  const blurOn = date && date.blurOn;

  const toggleBlur = (on) => {
    dateDoc.update({ blurOn: on });
  };

  const addTime = () => {
    dateDoc.update({
      expires: moment(date.expires).add(2, 'minutes').format(),
    });
  };

  const nextQuestion = () => {
    const nextQuestionNumber = questionNumber + 1;
    dateDoc.update({
      questionNumber: nextQuestionNumber,
      seenQuestions: _.concat(date.seenQuestions || [], questionNumber), // _.concat([1,2], 3) = [1,2,3]
    });
  };

  const previousQuestion = () => {
    if (questionNumber <= 0) {
      return;
    }
    const nextQuestionNumber = questionNumber - 1;
    dateDoc.update({
      questionNumber: nextQuestionNumber,
      seenQuestions: _.concat(value.seenQuestions || [], questionNumber),
    });
  };

  if (loading) {
    return <div>Loading </div>;
  }

  const isExpired =
    moment(date.expires).isBefore(moment()) || date.state === 'expired';

  if (isExpired) {
    return (
      <div>
        <NavBar />
        <Card>
          <div className="center mb5">
            <Card>
              <h3>This date is over. Thanks for dating with us!</h3>
              <img src={svgThankyou} width={400} />
              <Link to="/">
                <Button shape="pill" $style={{ width: '100%' }}>
                  Back to Home
                </Button>
              </Link>
            </Card>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div>
      <NavBar>
        <Link to="/">
          <Button kind="tertiary" $style={{ color: '#FF4136' }}>
            End Date
          </Button>
        </Link>
        <Button kind="tertiary" onClick={() => toggleBlur(!blurOn)}>
          Toggle Blur
        </Button>

        <span style={{ color: 'white' }}>
          <Countdown
            onComplete={() => dateDoc.update('state', 'expired')}
            onTick={(value) => {
              const dateDurationMinutes = Math.abs(
                moment
                  .unix(date.createdAt.seconds)
                  .diff(date.expires, 'minutes')
              );
              const timeLeftMinutes = moment.duration(value).asMinutes();
              const newPercentBlur = calculatePercentBlur(
                dateDurationMinutes,
                timeLeftMinutes,
                config.date.maxBlurDurationMinutes
              );

              setPercentBlur(newPercentBlur);
            }}
            date={moment(date.expires).valueOf()}
            renderer={({ minutes, seconds }) => (
              <span className="h4" style={{ color: 'white' }}>
                <span className="bold">
                  {minutes} min {seconds} sec{' '}
                </span>{' '}
                left
              </span>
            )}
          />
        </span>
      </NavBar>
      <div style={{ height: '80vh' }} className="flex flex-column relative">
        <Helmet>
          <title>QDate| Date: {dateID} </title>
        </Helmet>
        <div
          className="absolute"
          style={{
            width: '100%',
            height: '100%',
            filter: blurOn ? `blur(${blurPx}px)` : undefined,
          }}
        >
          <iframe
            title="video"
            className="absolute"
            frameBorder={0}
            src={`https://tokbox.com/embed/embed/ot-embed.js?embedId=${config.tokBoxEmbedID}&room=${dateID}&iframe=true`}
            width={'100%'}
            height={'100%'}
            scrolling="auto"
            allow="microphone; camera"
          />
        </div>
        <div
          className="absolute"
          style={{
            height: 175,
            width: '100%',
            bottom: 0,
            opacity: 0.9,
          }}
        />

        <div
          className="absolute question-box"
          style={{
            height: 175,
            width: '100%',
            bottom: 0,
          }}
        >
          <div style={{ zIndex: 100 }} className="p1 px2">
            <div className="flex justify-end items-center">
              <Button
                disabled={date.questionNumber <= 0}
                shape="round"
                size="compact"
                onClick={previousQuestion}
                $style={{ marginRight: '8px', border: '1px solid' }}
              >
                <ChevronLeft />
              </Button>
              <Button
                disabled={_.size(QUESTIONS) <= date.questionNumber - 1}
                shape="round"
                size="compact"
                onClick={nextQuestion}
                $style={{ border: '1px solid' }}
              >
                <ChevronRight />
              </Button>
            </div>
            <div className="mt2 light">
              <t.ParagraphLarge
                $style={{
                  color: 'white',
                  textShadow: '3px 5px 2px #474747',
                  fontFamily: 'Libre Baskerville',
                }}
              >
                {QUESTIONS[questionNumber]}
              </t.ParagraphLarge>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DateRoomIntro({ match }) {
  const dateID = match.params.id;
  const history = useHistory();

  return (
    <div style={{ height: '100%' }} className="flex flex-column relative">
      <NavBar>
        <span className="flex items-center" style={{ color: 'white' }}>
          <Link to="/">
            <Button>
              <ArrowLeft />
            </Button>
          </Link>
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => alert('Date URL Copied to Clipboard!')}
          >
            <span>Share Date Link</span>
          </CopyToClipboard>
        </span>
      </NavBar>
      <DateInstructions
        onComplete={() => history.push(`/dates/${dateID}/video`)}
      />
    </div>
  );
}
