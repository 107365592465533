import * as React from 'react';
import { useHistory } from 'react-router-dom';

import * as T from 'baseui/typography';
import { Button } from 'baseui/button';
import { NavBar } from 'src/components/nav';

import { api } from 'src/lib/api';
import { analytics } from 'src/lib/firebase';
import love2 from 'src/illustrations/love.svg';

export function Home() {
  const history = useHistory();

  const newDate = async () => {
    const { id } = await api.createDate();
    analytics.logEvent('date_created');
    history.push(`/dates/${id}`);
  };

  return (
    <>
      <NavBar />
      <div className="p1" style={{ marginTop: '3px solid black' }}>
        <div className="flex justify-center">
          <T.Display2
            $style={{ fontFamily: 'Libre Baskerville', marginBottom: '8px' }}
          >
            <span style={{ color: 'gray' }}>Q</span>Date
          </T.Display2>
        </div>
        <div className="center">
          <T.Label2
            color="gray"
            $style={{ fontWeight: 300, marginBottom: '8px' }}
          >
            Built for you because, well... we're in quarantine and video dates
            can be awkward.
          </T.Label2>

          <span style={{ width: 400 }}>
            <img src={love2} width={400} />
          </span>
        </div>
        <div className="flex justify-center">
          <div style={{ width: 500 }}>
            <div className="mt3 center">
              <Button
                shape="pill"
                onClick={newDate}
                overrides={{ BaseButton: { props: { className: 'mb1' } } }}
              >
                Begin a private date
              </Button>
              <div className="py2" style={{ color: 'gray' }}>
                Free and safe for everyone, no signup or download required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
