import _ from 'lodash';

export function calculatePercentBlur(
    dateDurationMinutes: number,
    timeLeftMinutes: number,
    maxBlurMinutes: number = 1
  ) {
    const totalDateTime = dateDurationMinutes; // moment.duration(10, 'minutes').asMinutes();
    const totalTime = timeLeftMinutes; // moment.duration(timeLeftMinutes).asMinutes();
    return (
      1 - _.min([totalDateTime - totalTime, maxBlurMinutes]) / maxBlurMinutes
    );
  }
  