import './App.css';
import * as React from 'react';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';

import { LightTheme, BaseProvider } from 'baseui';

import { DateRoom, DateRoomIntro } from 'src/components/date';
import { Home } from 'src/components/home';
import { analytics } from './lib/firebase';

const engine = new Styletron();

function usePageviewAnalytics() {
  const history = useHistory();
  React.useEffect(() => {
    history.listen((location) => {
      const page = location.pathname + location.search;
      analytics.setCurrentScreen(page);
      analytics.logEvent('page_view', {
        page_title: document.title,
        page_path: page,
      });
    });
  }, [history]);
}

function MainApp() {
  usePageviewAnalytics();

  return (
    <div
      className="mx-auto"
      style={{ maxWidth: 1024, backgroundColor: 'white', height: '100%' }}
    >
      <div style={{ backgroundColor: 'white' }}>
        <Switch>
          <Route path="/dates/:id" component={DateRoomIntro} exact />
          <Route path="/dates/:id/video" component={DateRoom} exact />
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
function App() {
  return (
    <Router>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <MainApp />
        </BaseProvider>
      </StyletronProvider>
    </Router>
  );
}

export default App;
