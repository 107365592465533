import * as firebase from 'firebase';
import { db } from 'src/lib/firebase'
import moment from 'moment';
import config from 'src/config/settings'

class API {
    db: firebase.firestore.Firestore;
    constructor(database) {
      this.db = database;
    }
    createDate() {
      return this.db.collection('dates').add({
        questionNumber: 0,
        name: 'Shan',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        state: 'open',
        expires: moment().add(config.date.defaultDurationMinutes, 'minutes').format(),
        blurOn: false,
      });
    }
  }

  export const api = new API(db);
  